import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

const FadeInUpAnimation = ({ children, delay, className, axis, align }) => {
  const containerRef = useRef(null);
  const [animationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const childElements = Array.from(container.children);

    gsap.set(container, { opacity: 0 });

    const animateContainer = () => {
      gsap.to(container, {
        opacity: 1,
        y: 0,
        duration: 0.4,
        delay: delay - delay,
      });
    };

    const animateChildElements = () => {
      gsap.fromTo(
        childElements,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 0.7,
          stagger: 0.2,
          delay: delay + 0.5,
        }
      );
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationTriggered) {
            setAnimationTriggered(true);
            animateContainer();
            animateChildElements();
            observer.disconnect();
          }
        });
      },
      { threshold: 0 }
    );

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, [delay, animationTriggered]);

  return (
    <div ref={containerRef} className={className} style={{
      "display" : "flex",
      "flex-direction" : axis,
      "align-items" : align,
      "width" : "100%",
      "height" : "100%"
    }}>
      {children}
    </div>
  );
};

FadeInUpAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  className: PropTypes.string,
};

FadeInUpAnimation.defaultProps = {
  delay: 0,
  className: '',
};

export default FadeInUpAnimation;
