import React, { useEffect , useState } from 'react'
import "./Explore.scss"
import { ExploreCard } from './ExploreCard'
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {storage} from "../firebase";
import { useLocation } from 'react-router-dom';

export const Explore = (props) => {
    const [Images, setImages] = useState([]);
    const location = useLocation();
    const data = location.state;
    let category;

    const fetchImages = (category) => {
        const imageListRef = ref(storage, `/${category}`)
        listAll(imageListRef).then((response) => {
            response.items.forEach(item => {
                getDownloadURL(item).then((url) => {
                    setImages((prev) => [...prev, url]);
                })
            });
        });
    }

    useEffect(() => {
        if(data !== null){
            category = data;
            fetchImages(category);
        } else{
            category = ["wedding", "modeling", "event-decor", "product-shoots", "uploads"];
            category.forEach((category)=>{
                fetchImages(category);
              })
        }
    }, [])
    
  return (
    <div className="explore">
        <div className={"header"}>
            <h1>Explore</h1>
        </div>
        <div className="photos-container">
            {Images && Images.map((image, props)=>{
                return <ExploreCard image={image} key={props} />
            })}
        </div>
    </div>
  )
}
