import './App.css';
import {Route, Routes} from "react-router-dom"
import {Home} from './home';
import Details from "./pages/details/details";
import { Explore } from './Explore/Explore';
import { Couple } from './couple/couple';
import { Review } from './pages/review/review';
import { Login } from './login/Login';
import {AboutUs} from "./pages/aboutus/aboutus";
import {getAnalytics} from "firebase/analytics";
import {app} from "./firebase"
import { Contactus } from './pages/contactus/Contactus';


function App() {
    return (
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/admin' element={<Login/>}/>
            <Route path='/couple' element={<Couple />} />
            <Route path='/wedding' element={<Details type={"wedding"}/>}></Route>
            <Route path='/modeling' element={<Details type={"modeling"}/>}></Route>
            <Route path='/explore' element={<Explore />}></Route>
            <Route path='/product-shoots' element={<Details type={"product-shoots"}/>}></Route>
            <Route path={'/event-decor'} element={<Details type={"event-decor"}/>} />
            <Route path={'/review'} element={<Review></Review>}></Route>
            <Route path={'/aboutus'} element={<AboutUs></AboutUs>}></Route>
            <Route path={'/special-occasions'} element={<Details type={"special-occasions"}></Details>}></Route>
            <Route path={'/cooperate'} element={<Details type={"cooperate"}></Details>}></Route>
            <Route path={'/maternity'} element={<Details type={"maternity"}></Details>}></Route>
            <Route path={'/album-shoots'} element={<Details type={"album-shoots"}></Details>}></Route>
            <Route path={'/contactus'} element={<Contactus/>}></Route>
        </Routes>
    );
}

export default App;
