import "../navbar/navbar.scss";
import { useEffect, useState } from "react";
import { BiMenu, BiWindowClose } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';
import { BsFacebook, BsGoogle, BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";
import lanternLogo from "../../assets/logo_black.png";

export default function NavBar() {

    var [isScrolled, setScrolled] = useState(false);
    var [isOpened, openSideMenu] = useState(false);


    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, [])

    function handleScroll() {

        if (window.scrollY > 30) {
            setScrolled(true);
        } else {
            setScrolled(false)
        }
    }


    function openMenu() {
        openSideMenu(!isOpened);
    }


    return (
        <div className={isScrolled ? "scrolled" : "nav"}>
            <div className={"page-padding"}>
                <div className="header">
                    <div id="leadingButton" onClick={openMenu}>
                        <BiMenu color="black" size={"30"}></BiMenu>
                    </div>
                    <img src={lanternLogo} alt="Logo"></img>
                    <div className="social-bar">
                        <Link to={`https://www.facebook.com/lanternstudio19?mibextid=ZbWKwL`}
                            target={"_blank"}><BsFacebook color={"black"} className={"social-icon"} height={30}></BsFacebook></Link>
                        <Link to={`https://www.instagram.com/lanternstudio19.in/?igshid=ZDdkNTZiNTM`}
                            target={"_blank"}><BsInstagram color={"black"}
                                className={"social-icon"} height={30}></BsInstagram></Link>
                        <Link to={`https://www.instagram.com/lanternstudio19.in/?igshid=ZDdkNTZiNTM`}
                            target={"_blank"}><BsGoogle color={"black"}
                                className={"social-icon"} height={30}></BsGoogle></Link>
                    </div>
                </div>
            </div>
            <div className={isOpened ? "side-menu-open" : "side-menu"}>
                <div className="close-menu" id="leadingButton" onClick={openMenu}>
                    <CgClose color="black" size={"30"}></CgClose>
                </div>
                <div className="social-bar">
                    <Link to={`https://www.facebook.com/lanternstudio19?mibextid=ZbWKwL`}
                        target={"_blank"}><BsFacebook color={"black"} className={"social-icon"} height={30}></BsFacebook></Link>
                    <Link to={`https://www.instagram.com/lanternstudio19.in/?igshid=ZDdkNTZiNTM`}
                        target={"_blank"}><BsInstagram color={"black"}
                            className={"social-icon"} height={30}></BsInstagram></Link>
                    <Link to={`https://www.instagram.com/lanternstudio19.in/?igshid=ZDdkNTZiNTM`}
                        target={"_blank"}><BsGoogle color={"black"}
                            className={"social-icon"} height={30}></BsGoogle></Link>
                </div>

                <a href={"#home"}>Home</a>
                <a href={"#explore"}>Explore</a>
                <Link to={'/aboutus'}>
                    About Us
                </Link>
                <a href={"#services"}>Services</a>
                <Link to={"contactus"}>Contact</Link>
            </div>
        </div>
    );
}