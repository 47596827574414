import React, { useEffect, useState } from 'react';
import "../aboutus/aboutus.scss";
import "./contactus.scss";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/main_logo.png";
import logo from "../../assets/logo.png";
import office from "../../assets/office.jpg";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../../firebase";

import {
    BiArrowBack,
    BiChat,
    BiMailSend,
    BiMap,
    BiPackage,
    BiPhone,
    BiSearch,
    BiSelection,
    BiSun,
    BiTime
} from "react-icons/bi";
import { Slider } from "../../components/slider/slider";
import Contact from '../../components/contact/contact';

export const Contactus = () => {


    const whatsapp = () => {
        const msg = "Hey! Are you available for a gig"
        const link = `https://api.whatsapp.com/send?phone=+919742002737&text=${msg}`;
        window.open(link, "blank");
    }

    const [isScrolled, setScrolled] = useState(false);
    const [officeImages, setOfficeImages] = useState([]);

    const getOfficeImages = async () => {
        setOfficeImages([]);
        const imageListRef = ref(storage, `/office`)
        listAll(imageListRef).then((response) => {
            response.items.forEach(item => {
                getDownloadURL(item).then((url) => {
                    setOfficeImages((prev) => [...prev, url]);
                })
            });
        });
    }

    useEffect(() => {
        window.scrollTo({ top: 0 });
        getOfficeImages();
        window.addEventListener("scroll", (e) => {
            if (window.scrollY > 10) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        });
    }, []);


    return (
        <div className='about-us contact-us'>
            <div className={isScrolled ? "about-bar-scrolled" : "about-bar"}>
                <Link to={"/"}><BiArrowBack color={'black'} size={"30"} height={"30"}></BiArrowBack></Link>
                <p>Contact Us</p>
            </div>
            <div className={"about-header contact-header"}>
                <div className="slider-front">
                    <img className='slider' src={office}></img>
                </div>
                <div className="details">
                    <h1>Capture All Your Memories And Never Forget Your Most Beautiful Days</h1>
                    <p><br />Visit Us or Get In Touch</p>
                    <a className={"about-button"} href={"#contact"}>Contact Us</a>
                </div>
            </div>
            <div className={"appointment-section"}>
                <div className={"details"}>
                    <div className="header">
                        <p className={"hey"}>Let's Talk</p>
                        <h1>Schedule Your Appointment</h1>
                        <p>
                            Talk About How You Wish To Capture Your Moments So We Can Craft Your Memories
                        </p>
                    </div>
                    <div onClick={whatsapp} className={"intro-bottom-button"}>
                        <div className="img"></div>
                        Whatsapp Us
                    </div>
                </div>
            </div>
            <div className='office-images'>
                {
                    officeImages.map((imageUrl) => {
                        return <div className='image-frame'>
                            <img src={imageUrl} alt='office image'></img>
                        </div>
                    })
                }
            </div>
            <div className="contact-section">
                <div className="details">
                    <div className="header">
                        <p className="hey">Hey There</p>
                        <p className="touch">Lets Get in touch</p>
                    </div>
                    <div className="contacts">
                        <div className="address con">
                            <BiMap className='icon map-icon' />
                            <p>No 25, 1st Main Rd, Srigandha kaval, Bharath Nagar, Chandrashaker layout, Srigandadakaval, Bengaluru, Karnataka 560091</p>
                        </div>
                        <div className="phone con">
                            <BiPhone className='icon call-icon' />
                            <p>+91 88922 25427</p>
                        </div>
                        <div className="mail con">
                            <BiMailSend className='icon mail-icon' />
                            <p>lanternstudio19@gmail.com</p>
                        </div>
                    </div>
                    <div className="header">
                        <p className="touch">Office Hours</p>
                    </div>
                    <div className="contacts">
                        <div className="phone con">
                            <BiSun className='icon call-icon' />
                            <p>Everyday</p>
                        </div>
                        <div className="mail con">
                            <BiTime className='icon mail-icon' />
                            <p>9 AM to 9 PM</p>
                        </div>
                    </div>
                </div>
                <div className="map">
                    <iframe frameBorder={0} className='gmap' loading='lazy' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.86336465439!2d77.4992705!3d12.980589799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3decf3c28a55%3A0x6097ce7cb585fec4!2sLantern%20Studio!5e0!3m2!1sen!2sin!4v1680852511405!5m2!1sen!2sin"></iframe>
                </div>
            </div>
            <div className="contact-form">
                <Contact />
            </div>
        </div>
    )
}
