import './aboutus.scss';
import {FaArrowLeft} from "react-icons/fa";
import logo from "../../assets/logo.png";
import {BiArrowBack, BiChat, BiPackage, BiSearch, BiSelection} from "react-icons/bi";
import Divider from "../../components/pagedivider/divider";
import {useEffect, useState} from "react";
import founder from "../../assets/founder.jpg";
import InvertedDivider from "../../components/pagedivider/inverted_divider";
import Contact from "../../components/contact/contact";
import {Link, useNavigation} from "react-router-dom";
import mainLogo from "../../assets/main_logo.png";
import signature from "../../assets/signature.png";

export const AboutUs = () => {

    const [isScrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0});
        window.addEventListener("scroll", (e) => {
            if (window.scrollY > 10) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        });
    }, []);

    return (
        <div className={"about-us"}>
            <div className={isScrolled ? "about-bar-scrolled" : "about-bar"}>
                <Link to={"/"}><BiArrowBack color={'black'} size={"30"}></BiArrowBack></Link>
                <p>About Us</p>
            </div>
        
            <div className={"about-founder"}>
                <div className={"founder-left"}>
                    <p className={"founder-name"}>
                        I am Susheel Kumar S<br/>
                    </p>
                    <p>
                        Lantern Studio was a initiative started in 2019.
                    </p>
                    <p>
                        I consider myself extremely fortunate to be able to turn my passion into a career.
                    </p>
                    <p>
                        Nothing gives me a greater sense of fulfilment than being able to share
                        with my clients particular moments I photographed and recorded on their big day,
                        moments that can bring them joy, bring them to tears,
                        or just help them remember their loved ones for years to come.
                    </p>
                    <p>
                        I have been working really hard to create the initial "wow" factor
                        for each and every customer because it's so important.
                    </p>
                    <p>
                        The best pictures, though, aren't often the ones that
                        instantly bring back fond memories; rather,
                        they're often the ones that grow in significance over time.
                    </p>
                </div>
                <div className={"founder-right"}>
                    <div className={"founder-image"}>
                        <img src={founder} alt={"founder"}/>
                        <img src={signature} alt={"signature"} className={"signature"}/>
                        <p>(Founder - Fashion Designer)</p>
                    </div>
                </div>
            </div>
            <div className={"about-importance"}>
                <h1>
                    What Makes Us Smile!<br/>
                    We Like To Have Fun, But Still  deliver top results
                </h1>
                <div className={"importance-inner"}>
                    <div className={"importance-tile"}>
                        <h2>Our Philosophy</h2>
                        <svg width="450" height="16" viewBox="0 0 584 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="11.9982" y1="9.5" x2="575.998" y2="7.46022" stroke="black" />
                            <ellipse cx="8.5" cy="8" rx="8.5" ry="8" fill="black" />
                            <circle cx="576.5" cy="7.5" r="7.5" fill="black" />
                        </svg>
                        <p>
                            Our work makes us smile and occasionally laugh out loud.
                            This phase of life will make you laugh, from silly moments to
                            bouts of laughter and everything in between.
                        </p>
                    </div>
                    <div className={"importance-tile"}>
                        <h2>Our Way Of Life</h2>
                        <svg width="450" height="16" viewBox="0 0 584 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="11.9982" y1="9.5" x2="575.998" y2="7.46022" stroke="black" />
                            <ellipse cx="8.5" cy="8" rx="8.5" ry="8" fill="black" />
                            <circle cx="576.5" cy="7.5" r="7.5" fill="black" />
                        </svg>
                        <p>
                            We ensure incomparable and everlasting imagery by uniquely combining
                            proficiency and creativity. We ensure that you look your
                            absolute best on the most important day of your life by providing
                            gentle direction and guidance. When it comes to telling stories
                            with your treasured moments, we are unrivalled.
                        </p>
                    </div>
                </div>
            </div>
            <div className={"about-proc"}>
                <div className={"grid-inner"}>
                    <h1>How It Works</h1>
                    <div className={"proc-grid"}>
                        <div className={"proc-tile"}>
                            <BiChat color={"black"} size={100}></BiChat>
                            <h2>Chat With An Expert</h2>
                            <p>We ensure all your moments are never missed</p>
                        </div>
                        <div className={"proc-tile"}>
                            <BiSelection color={"black"} size={100}></BiSelection>
                            <h2>Choosing a package</h2>
                            <p>Professional photographers, videographers, designers,
                                and editors of the highest calibre</p>
                        </div>
                        <div className={"proc-tile"}>
                            <BiSearch color={"black"} size={100}></BiSearch>
                            <h2>Event customization</h2>
                            <p>Absolute best cinematographers to make
                                you the protagonist of your story</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"about-contact"}>
                <Contact/>
            </div>
        </div>
    );
}