import React, { useEffect, useState } from 'react';
import HeroSlider, { Slide } from "hero-slider";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";


export const Slider = (props) => {
  const [imageList, setImageList] = useState([]);
  const [isMobile, setMobile] = useState(false);
  const imageListRef = ref(storage, `${props.location}/`);

  useEffect(() => {
    if (window.screen.width <= 900) {
      setMobile(true);
    } else {
      setMobile(false)
    }
    listAll(imageListRef).then((response) => {
      response.items.forEach(item => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        })
      });
    });
  }, [])


  return (
    <HeroSlider autoplay={{ autoplayDuration: 2500 }} manager={{ isMobile: false }} className="img" height={isMobile ? "25vh" : "100vh"} animations={{ slidingAnimation: "fade" }} accessability={{ shouldDisplayButtons: false }} controller={{ initialSlide: 1, slidingDuration: 500, slidingDelay: 100, }}>
      {imageList.map((image, props) => {
        return <Slide key={props} background={{ backgroundImageSrc: image }} />
      })}
    </HeroSlider>
  )
}
