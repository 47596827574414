import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage} from "firebase/storage";
import {getFirestore} from "firebase/firestore";
 
const firebaseConfig = {
  apiKey: "AIzaSyCUU0LiiPNyr5fpWC8Av559kGbbc_b6v3U",
  authDomain: "lantern-studio.firebaseapp.com",
  projectId: "lantern-studio",
  storageBucket: "lantern-studio.appspot.com",
  messagingSenderId: "164687314331",
  appId: "1:164687314331:web:cabc94a589bd8ae242353e",
  measurementId: "G-RQZXXKGLL7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);