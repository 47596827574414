import "./details.scss";
import React, {useEffect, useState} from "react";
import { doc, getDoc } from "firebase/firestore";
import "swiper/css";
import "swiper/css/scrollbar";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {storage, db} from "../../firebase";
import weedding from "../../assets/wedding.png";
import product from "../../assets/product.jpg"
import event_decor from "../../assets/evet_decor_card.jpeg";
import special_occ from "../../assets/special_oce.jpg";
import cooperate from "../../assets/coorporate.jpg";
import baby from "../../assets/Maternity.jpg";
import { DetailPage } from "./detailPage";
import album from "../../assets/album.jpg";

export default function Details(props) {
    const type = props.type;
    const [imageList, setImageList] = useState([]);
    const [socials, setSocials] = useState();


    const fetchImages = () => {
        setImageList([]);
        const imageListRef = ref(storage, `/${props.type}`);
        listAll(imageListRef).then((response) => {
            response.items.forEach(item => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                })
            });
        });
    }

    const fetchSocials = async () =>{
        const socialRef = doc(db, "Socials", `${props.type}`);
        const Socialsnap = await getDoc(socialRef);

        if(Socialsnap.exists()){
            setSocials(Socialsnap.data());
        }
    }

    useEffect(() => {
       fetchSocials();
       fetchImages(); 
    },[])
    
    if (type === "wedding") {
        return <DetailPage quote={"Two hearts in love need no words."} 
        author={"Marceline Desbordes-Valmore"} 
        title={"Explore and Experience Weddings with us"} 
        bgImage={weedding} 
        socials={socials} imageList={imageList} type={type}/>
    }
    if(type === "modeling"){
        return <DetailPage quote={"Everyone Is Beautiful In Their Own Way"} 
        author={"Marceline Desbordes-Valmore"} 
        title={"Find Your Inner Beauty With Us"} 
        bgImage={weedding} 
        socials={socials} imageList={imageList} type={type}/>
    }
    if(type === "product-shoots"){
        return <DetailPage quote={'“Always Deliver More Than Expected.”'} 
        author={"Larry Page"} 
        title={"Make Your Product Look Perfect"} 
        bgImage={product} 
        socials={socials} imageList={imageList} type={type}/>
    }
    if(type === "event-decor"){
        return <DetailPage quote={'“Always Deliver More Than Expected.”'}
        author={"Larry Page"} 
        title={"Make Your Product Look Perfect"} 
        bgImage={event_decor} 
        socials={socials} imageList={imageList} type={type}/>
    }
    if(type === "special-occasions"){
        return <DetailPage
            quote={"Unforgettable And Happiest Days"}
            title={"Days You'll Never Forget"}
            bgImage={special_occ}
            socials={socials}
            imageList={imageList}
            type={type}
        ></DetailPage>
    }
    if(type === "cooperate"){
        return <DetailPage
            quote={"If everyone helps to hold up the sky, then one person does not become tired."}
            author={"Askhari Johnson Hodari"}
            title={"Coorperate Shoots"}
            bgImage={cooperate}
            socials={socials}
            imageList={imageList}
            type={type}
            ></DetailPage>
    }
    if(type === "maternity"){
        return <DetailPage
            quote={"A Little Bit Of Heaven Sent Down To Earth."}
            author={"Roland Leonhardt"}
            title={"Maternity & Baby Shoots"}
            bgImage={baby}
            socials={socials}
            imageList={imageList}
            type={type}
        ></DetailPage>
    }if(type === "album-shoots"){
        return <DetailPage
        quote={"Album Quote"}
        author={"Author Name"}
        title={"Album Shoot Subheading"}
        bgImage={album}
        socials={socials}
        imageList={imageList}
        type={type}
    ></DetailPage>
    }
}