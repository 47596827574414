import React, { useState } from 'react';
import "./updateLinks.scss";
import { doc, updateDoc } from "firebase/firestore"; 
import {db} from "../firebase";
import { Admin } from '../login/admin';
import { BiArrowBack } from 'react-icons/bi';

export const UpdateLinks = () => {
  const [location, setLocation] = useState("");
  const [socialmedia, setSocialmedia] = useState("");
  const [link, setLink] = useState("");
  const [GoBack, setGoBack] = useState(false);

  const updateLink = async() =>{
    if(link === ""){
      alert("Empty Link");
      return;
    }
    if(socialmedia === "youtube"){
      await updateDoc(doc(db,"Socials",location),{
        "youtube": link,
      }).then(
        alert("Link updated Successfully!")
      );
    }
    if(socialmedia === "instagram"){
      await updateDoc(doc(db,"Socials",location),{
        "instagram": link,
      }).then(
        alert("Link updated Successfully!")
      );
    }
    if(socialmedia === "facebook"){
      await updateDoc(doc(db,"Socials",location),{
        "facebook": link,
      }).then(
        alert("Link updated Successfully!")
      );
    }
  } 

  const backFunction = () =>{
    setGoBack(true);
  }

  if(GoBack === true){
    return <Admin />
  }


  return (
    <div className="update-links">
      <BiArrowBack onClick={backFunction} color={"white"} className={"leading-icon"}></BiArrowBack>
      <h2>Update Links</h2>
      <div className="update-options">
          <div className="selects">
            <select onChange={(e)=>{setLocation(e.target.value)}}>
              <option value="">choose location</option>
              <option value="wedding">wedding</option>
              <option value="modeling">modeling</option>
              <option value="product-shoots">product-shoots</option>
              <option value="event-decor">event-decor</option>
              <option value="special-occasions">special-occasions</option>
              <option value="cooperate">cooperate</option>
              <option value="maternity">maternity</option>
            </select>
            <select onChange={(e)=>{setSocialmedia(e.target.value)}}>
              <option value="">choose Media</option>
              <option value="youtube">Youtube Video</option>
              <option value="instagram">Instagram Post</option>
              <option value="facebook">Facebook Post</option>
            </select>
          </div>
          <input onChange={(e)=>{setLink(e.target.value)}} type="text" placeholder='Paste the link'/>
          <button onClick={updateLink}>Upload</button>
        </div>
    </div>
  )
}
