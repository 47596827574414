import { useEffect, useState } from "react";
import { BsStarFill } from "react-icons/bs";



export const Rating = (props) =>{

  var rating = Array(props.rating).fill(1);
  
  return(
    <div className="rating">
      {
        rating.map((v , props) => <BsStarFill key={props} color="gold"></BsStarFill>)
      }
    </div>
  )
}