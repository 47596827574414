import React, { useEffect, useState } from 'react'
import "./managecouple.scss";
import Compressor from 'compressorjs';
import {storage} from "../firebase.js";
import {ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage";
import { collection, addDoc, getDocs, setDoc, doc ,deleteDoc } from "firebase/firestore"; 
import {db} from "../firebase";
import { BiArrowBack } from 'react-icons/bi';
import { Admin } from '../login/admin';

export const ManageCouple = () => {
    const [compressedImage , setCompressedImage ] = useState(null);
    const [CoupleName, setCoupleName] = useState("");
    const [location, setLocation] = useState("");
    const [coupleID , setcoupleID] = useState("");
    const [DownloadUrl, setDownloadUrl] = useState("");
    const [coupleButton, setcoupleButton] = useState(false);
    const [addButton, setaddButton] = useState(false);
    const [deleteButton, setdeleteButton] = useState(false);
    const [GoBack, setGoBack] = useState(false);

    const CompressImage = (image) =>{
        const comprosser = new Compressor(image,{
          quality: 0.2,
          success: (compressedResult)=>{
            setCompressedImage(compressedResult);
          }
        });
        console.log("Compressed");
    }
    
    const uploadImagetoStorage = () =>{
        if(compressedImage == null){
          alert("Try again please");
          return;
        };
        const imageRef = ref(storage, `couples/${compressedImage.name}`);
        uploadBytes(imageRef, compressedImage ).then((image)=>{
            alert("image Uploaded!");
            getDownloadURL(image.ref).then((url)=>{
                setDownloadUrl(url);
                if(location === ""){
                  setaddButton(true);
                }
                if(coupleID === ""){
                  setcoupleButton(true);
                }
            })
        });
    }

    useEffect(() => {
      if(compressedImage !== null){
        uploadImagetoStorage();
      }
    }, [compressedImage]);

    const addCouple = async () =>{
      if(!coupleButton){
        alert("Image not uploaded");
        return;
      }
      await addDoc(collection(db, "couples"),{
        "coupleName": CoupleName,
        "genre": location,
        "ImageName": [compressedImage.name],
        "gallary": [DownloadUrl],
        "image": DownloadUrl,
      }).then(()=>{
        alert("couple added");
        setCompressedImage(null);
        setcoupleButton(false);
      })
    }

    // get couples data
    const [couplesdata, setCouplesdata] = useState([]);

    const getcouples = async () => {
      await getDocs(collection(db, 'couples')).then((query)=>{
          const data = query.docs.map((doc) => ({...doc.data(), id: doc.id}));
          setCouplesdata(data);
      })
  }

  const getCouple = () =>{
    for (let index = 0; index < couplesdata.length; index++) {
      if(couplesdata[index].id === coupleID){
        return couplesdata[index];
      }
    }
  }

  const addImage = async()=>{
    if(compressedImage === null){
      alert("First Add Image")
      return;
    }
    const couple = getCouple();
    const CoupleGallery = couple.gallary;
    CoupleGallery.push(DownloadUrl);
    const ImageName = couple.ImageName;
    ImageName.push(compressedImage.name);

    await setDoc(doc(db,"couples",coupleID),{
      "coupleName": couple.coupleName,
        "genre": couple.genre,
        "gallary": CoupleGallery,
        "ImageName": ImageName,
        "image": couple.image,
    }).then(()=>{
      alert("added Image!");
      setaddButton(false);
    })
  }

    useEffect(()=>{
      getcouples();
    },[])

    // delete couple
    const deleteCouple = async() =>{
      if(coupleID === ""){
        alert("Select a Couple first");
        return;
      }
      const couple = getCouple();
      const coupleImages = couple["ImageName"];
      coupleImages.forEach(async (image , index) => {
        const imgRef = ref(storage, `couples/${image}`);
        await deleteObject(imgRef).then(()=>{
          console.log(`Deleted Image number - ${index + 1}`);
        })
      });
      await deleteDoc(doc(db, "couples" , coupleID)).then(()=>{
        alert("coupled Deleted");
        setdeleteButton(false);
      });
    }
    
    // Back button login
    const backFunction = () =>{
      setGoBack(true);
    }

    if(GoBack == true){
      return <Admin />
    }


  return (
    <div className="manageCouple">
      <BiArrowBack onClick={backFunction} color={"white"} className={"leading-icon"}></BiArrowBack>
        <h2>Add Couples</h2>
        <div className="addOptions">
            <input className='text-input' onChange={(e)=>{setCoupleName(e.target.value)}} type="text" placeholder='Couple Name'/>
            <select onChange={(e)=>{setLocation(e.target.value)}}>
              <option value="">choose location</option>
              <option value="wedding">wedding</option>
              <option value="modeling">modeling</option>
              <option value="product-shoots">product-shoots</option>
              <option value="event-decor">event-decor</option>
              <option value="special-occasions">special-occasions</option>
              <option value="cooperate">cooperate</option>
              <option value="maternity">maternity</option>
            </select>
            <input className='custom-file-input' id='imageInput' type="file" onChange={(e)=>{{CompressImage(e.target.files[0]);}}}/>
            <button className={coupleButton ? "button-active" : "button-disable"} onClick={addCouple}>Add Couple</button>
        </div>
        <h2>Add Images</h2>
        <div className="manageoptions">
        <select onChange={(e)=>{setcoupleID(e.target.value)}}>
        <option value="">choose couple</option>
        {
          couplesdata && couplesdata.map((data, props)=>{
          return <option key={props} value={data.id}>{data.coupleName}</option>
          })
        }  
        </select>
        <input className='custom-file-input' id='imageInput' type="file" onChange={(e)=>{{CompressImage(e.target.files[0]);}}}/>
        <button className={addButton ? "button-active" : "button-disable"} onClick={addImage}>Add Image</button>
        </div>
        <h2>Delete Couples</h2>
        <div className="deleteCouple">
          <select onChange={(e)=>{setcoupleID(e.target.value); setdeleteButton(true)}}>
            <option value="">choose couple</option>
            {
              couplesdata && couplesdata.map((data, props)=>{
              return <option key={props} value={data.id}>{data.coupleName}</option>
              })
            }
          </select>
          <button className={deleteButton ? "button-active" : "button-disable"} onClick={deleteCouple}>Delete Couple</button>
        </div>
    </div>
  )
}
