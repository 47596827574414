import React, { useState } from 'react';
import { ManageCouple } from '../upload/manageCouple';
import { UpdateLinks } from '../upload/updateLinks';
import { UploadImage } from '../upload/uploadImage';

import "./admin.scss";
export const Admin = () => {
    const [navpage, setnavpage] = useState("");

    if(navpage === "photos"){
        return <UploadImage />
    }

    if(navpage === "socials"){
        return <UpdateLinks />
    }

    if(navpage === "couples"){
        return <ManageCouple />;
    }

  return (
    <div className='admin'>
        <h1 className='greeting'>Hi , {process.env.REACT_APP_USER}</h1>
        <div className="options">
            <div onClick={()=>{setnavpage("socials")}} className="option">Manage Socials</div>
            <div onClick={()=>{setnavpage("photos")}} className="option">Manage Photos</div>
            <div onClick={()=>{setnavpage("couples")}} className="option">Manage Couples</div>
        </div>
        <div className="help">
            <h5>Need Help?</h5>
            <h6>9767426310, 8329181739</h6>
        </div>
    </div>
  )

}
