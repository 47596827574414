import "./review.scss"
import {useEffect, useState} from "react";
import {Rating, Typography} from "@mui/material";
import {addDoc, collection, setDoc} from "firebase/firestore";
import {db} from "../../firebase";

export const Review = () => {
    const [name, setName] = useState("");
    const [value, setValue] = useState(0);
    const [review, setReview] = useState("");

    useEffect(()=>{}, []);

    const sendReview = async () =>  {
        const docRef = await addDoc(collection(db, 'reviews'), {
            'author' : name,
            'review' : review,
            'value' : value
        }).then((v) => {
            alert("Review Sent! Thank you.")
        })
    }

    return (
        <div className={"review"}>
            <div className={"review-container"}>
                <h1>Feedback</h1>
                <p>Thanks! for giving us the opportunity to work with you.</p>
                <div className={"review-form"}>
                    <Rating name={"simple-controlled"}
                            size={"large"}
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                    ></Rating>
                    <p className="label">Name</p>
                    <input onChange={(e) => {
                        setName(e.target.value)
                    }} type="text"/>
                    <p className="label">Review</p>
                    <textarea onChange={(e) => {
                        setReview(e.target.value)
                    }}/>
                </div>
                <button className="submit-button" onClick={sendReview}>Submit</button>
            </div>
        </div>
    )
}