
import React, { useState } from 'react';
import {storage} from "../firebase.js";
import {ref, uploadBytes,listAll, getDownloadURL, deleteObject} from "firebase/storage";
import Compressor from 'compressorjs';
import "./uploadpage.scss";
import { BiArrowBack } from 'react-icons/bi';
import { Admin } from '../login/admin.jsx';


export const UploadImage = () => {
    const [compressedImage , setCompressedImage ] = useState(null);
    const [uploadPath, SetuploadPath] =useState("uploads");
    const [imageList, setImageList] = useState([]);
    const [GoBack, setGoBack] = useState(false);

    const uploadImagetoStorage = () =>{
        if(compressedImage === null){
          alert("Try again please");
          return;
        };
        const imageRef = ref(storage, `${uploadPath}/${compressedImage.name}`);
        uploadBytes(imageRef, compressedImage ).then(()=>{
            alert("image Uploaded!");
            setCompressedImage(null);
        })
      }

    const CompressImage = (image) =>{
      const comprosser = new Compressor(image,{
        quality: 0.2,
        success: (compressedResult)=>{
          setCompressedImage(compressedResult);
        }
      });
    }

    const fetchImages = () =>{
      setImageList([]);
      const imageListRef = ref(storage, `${uploadPath}/`);
      listAll(imageListRef).then((response)=>{
        response.items.forEach(item => {
          getDownloadURL(item).then((url)=>{
            setImageList((prev) => [...prev,{"imageUrl": url, "name": item.name}]);
          })
        });
      });
    }

    const deleteImg = async(name)=>{
      const imgRef = ref(storage, `${uploadPath}/${name}`);
      await deleteObject(imgRef).then(()=>{
        alert("deleted");
        fetchImages();
      })
    }

    const backFunction = () =>{
      setGoBack(true);
    }
  
    if(GoBack === true){
      return <Admin />
    }
  

  return (
    <div className="upload-page">
      <BiArrowBack onClick={backFunction} color={"white"} className={"leading-icon"}></BiArrowBack>
        <h2>Upload an image</h2>
        <div className="upload-options">
          <input className='custom-file-input' id='imageInput' type="file" onChange={(e)=>{{CompressImage(e.target.files[0]);}}}/>
          <select onChange={(e)=>{SetuploadPath(e.target.value)}}>
              <option value="">choose location</option>
              <option value="uploads">front carousal</option>
              <option value="wedding">wedding</option>
              <option value="modeling">modeling</option>
              <option value="product-shoots">product-shoots</option>
              <option value="event-decor">event-decor</option>
              <option value="special-occasions">special-occasions</option>
              <option value="cooperate">cooperate</option>
              <option value="maternity">maternity</option>
          </select>
          <button onClick={uploadImagetoStorage}>Upload</button>
        </div>
        <hr />
        <div className="fetch-options">
        <h3>Manage images in</h3>
          <select onChange={(e)=>{SetuploadPath(e.target.value)}}>
            <option value="">select</option>
            <option value="uploads">front carousal</option>
            <option value="wedding">wedding</option>
            <option value="modeling">modeling</option>
            <option value="product-shoots">product-shoots</option>
            <option value="event-decor">event-decor</option>
            <option value="special-occasions">special-occasions</option>
            <option value="cooperate">cooperate</option>
            <option value="maternity">maternity</option>
          </select>
          <button onClick={fetchImages}>Fetch</button>
          {imageList && <p>Found {imageList.length} Images</p>}
        </div>
        <div className="fetched-images">
          {imageList.map((image, props)=>{
            return <div key={props} className="fetch-img"><img className='fetched-image' key="" src={image.imageUrl} /><button onClick={()=>{deleteImg(image.name)}}>delete</button></div>
          })}
        </div>
    </div>
  )
}
