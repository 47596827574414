import React from 'react';
import NavBar from './components/navbar/navbar';
import HomePage from './pages/homepage/homepage';


export const Home = () => {
  return (
  <>
    <NavBar />
    <HomePage />
  </>    
  )
}
