import "./homepage.scss";
import WorkCard from "../../components/workcard/workcard";
import Contact from "../../components/contact/contact";
import wedding_card from "../../assets/wedding_card.png";
import modeling_card from "../../assets/modeling_card.png";
import product_card from "../../assets/product_card.jpg";
import eventDecor_card from "../../assets/evet_decor_card.jpeg";
import { Link } from "react-router-dom";
import { getDocs, collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import FadeInUpAnimation from "../../components/animation/animation";
import Mansory, { ResponsiveMasonry } from "react-responsive-masonry";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Rating } from "../../components/rating/rating";
import cooperate_card from "../../assets/coorporate.jpg";
import special_oce_card from "../../assets/special_oce.jpg";
import maternity_card from "../../assets/Maternity.jpg";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import countries from "../../assets/countries.jpeg";
import album_page from "../../assets/album.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


export default function HomePage() {

    const [isMobile, setMobile] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [recentWork, setRecentWork] = useState([]);
    const [introVideo, setIntroVideo] = useState([]);
    const [explore, setExplore] = useState([]);
    const [aboutus, setAboutUs] = useState([]);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [autoPlay, setAutoPlay] = useState(false);
    const [animation, setAnimation] = useState("");


    const getIntroVideo = async () => {
        await getDocs(collection(db, 'others')).then((query) => {
            const data = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setIntroVideo(data);
        });
    }

    const getReviews = async () => {
        await getDocs(collection(db, 'reviews')).then((query) => {
            const data = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setReviews(data);
        })
    }

    const getExplore = () => {
        setExplore([]);
        const imageListRef = ref(storage, `/uploads`)
        listAll(imageListRef).then((response) => {
            response.items.forEach(item => {
                getDownloadURL(item).then((url) => {
                    setExplore((prev) => [...prev, url]);
                })
            });
        });
    }


    const getRecentWork = () => {
        setRecentWork([]);
        const imageListRef = ref(storage, `/recent_work`)
        listAll(imageListRef).then((response) => {
            response.items.forEach(item => {
                getDownloadURL(item).then((url) => {
                    setRecentWork((prev) => [...prev, url]);
                })
            });
        });
    }

    const getAboutUs = () => {
        setAboutUs([]);
        const imageListRef = ref(storage, `/homeabout`)
        listAll(imageListRef).then((response) => {
            response.items.forEach(item => {
                getDownloadURL(item).then((url) => {
                    setAboutUs((prev) => [...prev, url]);
                })
            });
        });
    }


    function extractVideoId(url) {
        let regExp = /youtu\.be\/([^#\&\?]{11})|youtube\.com\/.*v=([^#\&\?]{11})/;
        let match = url.match(regExp);
        if (match && match.length >= 2) {
            return match[1] || match[2];
        }
        return null;
    }

    function delay() {

    }

    useEffect(() => {
        if (window.screen.width <= 900) {
            setMobile(true);
        } else {
            setMobile(false)
        }
        window.setTimeout(() => {
            setAutoPlay(true);
            setAnimation("fade");
            console.log(autoPlay);
        }, 2500);
        getReviews();
        getRecentWork();
        getIntroVideo();
        getExplore();
        getAboutUs();
        delay();
    }, [])

    return (
        <div className={"homepage"}>
            <div className={"homepage-landing"}>
                <div className={"homepage-carousel"}>
                    <div className="slider-front">
                        <Carousel
                            className="my-Swiper2"
                            renderIndicator={false}
                            autoPlay={autoPlay}
                            thumbWidth={isMobile ? 100 : 200}
                            showStatus={false}
                            animationHandler={"fade"}
                            interval={2000}
                            infiniteLoop
                            autoFocus
                            dynamicHeight={false}
                            width={"100%"}
                        >
                            {
                                explore.map((img) => <img src={img} alt="homepage_image"></img>)
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
            <div id="explore" className={"homepage-middle"}>
                <div className={"homepage-middle-header"}>
                    <FadeInUpAnimation axis={"column"}>
                        <h1>
                            A Moment In Time<br />
                            Eternity captured in the moment.
                        </h1>
                        <p>
                            We are Lantern Studio, a photography business with headquarters in Bangalore.
                            We're here to capture a moment in time that will live in your memory for the rest of your life.
                        </p>
                        <div className="homepage-middle-work">
                            {
                                aboutus.map((url) => <img src={url} alt="aboutIMG"></img>)
                            }
                        </div>
                    </FadeInUpAnimation>
                    <Link to={"explore"} className={"homepage-middle-button"}>
                        <div className="border"></div>
                        Explore
                    </Link>
                </div>
            </div>
            <div className="divider"></div>
            <div className={"aboutpage"}>
                <div className={"aboutpage-padding"}>
                    <FadeInUpAnimation>
                        <div className="aboutus-founder">
                            <img src={countries} alt="founder"></img>
                        </div>
                    </FadeInUpAnimation>
                    <div className={"aboutpage-content"}>
                        {/* <h3>About Us</h3> */}
                        <FadeInUpAnimation axis={"column"} align={"center"}>
                            <svg width="450" height="16" viewBox="0 0 584 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <line x1="11.9982" y1="9.5" x2="575.998" y2="7.46022" stroke="white" />
                                <ellipse cx="8.5" cy="8" rx="8.5" ry="8" fill="white" />
                                <circle cx="576.5" cy="7.5" r="7.5" fill="white" />
                            </svg>
                            <p>
                                We believe that the lens speaks more than words and a single photograph is enough to tell an
                                entire story. Being hopelessly romantics, our motto is to make you feel like a celebrity on
                                your special day!
                                Hailing from a commercial and lifestyle photography background, our styling and lighting
                                techniques have hints of fashion elements, adding glamorous and endearing touch to make your
                                big day seem larger than <br></br>life, right out of a movie!
                                Being globetrotters, we cater to clients from all over the world.
                                From exotic destinations and wedding food, to the lovely couple, friends, relatives and
                                family, we love everything about weddings. Be it the blushing bride, the groom stealing a
                                glance, friends and family beaming with joy, wedding locations or traditions,
                                rest assured that we will capture it all!
                            </p>
                            <Link to={'/aboutus'} className={"about-button"}>
                                Know More
                            </Link>
                        </FadeInUpAnimation>
                    </div>

                </div>
            </div>
            <div className="divider"></div>
            <div className="home-intro">
                <FadeInUpAnimation axis={"column"} align={"center"}>
                    {
                        introVideo.map((intro_link) => {
                            let url = extractVideoId(intro_link['intro_link']);
                            return <iframe frameBorder={"0"} className="intro-link" src={"https://www.youtube.com/embed/" + url}></iframe>
                        })
                    }
                </FadeInUpAnimation>
            </div>
            <div className="recent-work">
                <h1>Our Recent Work</h1>
                <div className="recent-work-wrap">
                    <div className="recent-work-container">
                        {recentWork && recentWork.map((image, props) => <FadeInUpAnimation>
                            <div className="recent-container">
                                <img src={image} alt="recent_image" />
                            </div>
                        </FadeInUpAnimation>)}
                    </div>
                </div>
            </div>
            <div className="divider"></div>
            <div className={"homepage-services"}>
                <div id="services" className={"homepage-services-padding"}>
                    <h1>Our Services</h1>
                    <div className={"homepage-services-inner"}>
                        <WorkCard title={"Wedding Shoots"}
                            imageSrc={wedding_card}
                            link={"wedding"}
                        ></WorkCard>

                        <WorkCard title={"Modeling Shoots"}
                            imageSrc={modeling_card}
                            link={"modeling"}
                        ></WorkCard>

                        <WorkCard title={"Cooperate Shoots"}
                            imageSrc={cooperate_card}
                            link={"cooperate"}
                        ></WorkCard>

                        <WorkCard title={"Event Decor"}
                            imageSrc={eventDecor_card}
                            link={"event-decor"}
                        ></WorkCard>

                        <WorkCard title={"Maternity/Baby"}
                            imageSrc={maternity_card}
                            link={"maternity"}
                        ></WorkCard>

                        <WorkCard title={"Special Occasions"}
                            imageSrc={special_oce_card}
                            link={"special-occasions"}
                        ></WorkCard>

                        <WorkCard title={"Product shoots"}
                            imageSrc={product_card}
                            link={"product-shoots"}
                        ></WorkCard>

                        <WorkCard title={"Album  shoots"}
                            imageSrc={album_page}
                            link={"album-shoots"}
                        ></WorkCard>
                    </div>
                </div>
            </div>
            <div className="divider"></div>
            <div className={"review-section"}>
                <h1>Can't Trust?<br />Listen From Our Clients</h1>
                <div className={"review-swiper"}>
                    <Swiper
                        slidesPerView={isMobile ? 1 : 3}
                        spaceBetween={50}
                        slidesPerGroup={isMobile ? 1 : 3}
                        navigation
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{ delay: 2000 }}
                        modules={[Navigation, Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {
                            reviews.map((data, props) =>
                                <SwiperSlide key={props} className={"review-tile"}>
                                    <div className={"review-author"}>
                                        <div className={"author-avatar"}>
                                            <p>
                                                {
                                                    data['author'].substring(0, 1)
                                                }
                                            </p>
                                        </div>
                                        <div className={'author-rating'}>
                                            <h3>{
                                                data['author']
                                            }</h3>
                                            <div className={'rating'}>
                                                <Rating rating={data['value']}></Rating>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        {
                                            data['review']
                                        }
                                    </p>

                                </SwiperSlide>)
                        }
                    </Swiper>
                </div>
            </div>
            <Contact></Contact>
        </div>
    );
}