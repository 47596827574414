import React, { useState } from 'react';
import { Admin } from './admin';
import "./login.scss";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(false);


  const verifyLogin = () =>{
    if(username === process.env.REACT_APP_USER && password === process.env.REACT_APP_PASS){
      setIsLogin(true);
    } else{
      alert("Wrong Credentials")
    }
  }

  if(isLogin){
    return <Admin />;
  }


  return (
    <div className="login">
      <h2>Admin Login</h2>
      <div className="form">
        <input onChange={(e)=>{setUsername(e.target.value)}} placeholder='Username' type="text"/>
        <input onChange={(e)=>{setPassword(e.target.value)}} placeholder='Password' type="password" />
        <button onClick={verifyLogin}>Sign In</button>
      </div>
    </div>
  )
}
