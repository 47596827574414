import { Link } from "react-router-dom";
import "./workcard.scss";
import {HiArrowCircleRight} from "react-icons/hi";
import {TfiArrowCircleRight} from "react-icons/tfi";

export default function WorkCard(props){
    return(
        <Link to={props.link} className={"work-card"} onClick={() => {return props.onClick()}}>
            <img src={props.imageSrc} alt={"work"}/>
            <p>{props.title}</p>
        </Link>
    );
}


