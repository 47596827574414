import { useState } from "react";
import { Link } from "react-router-dom";
import "./contact.scss";
import { TextField } from "@mui/material";

export default function Contact() {
    const [Name, setName] = useState("");
    const [Contact, setContact] = useState("");
    const [Message, setMessage] = useState("");

    const whatsapp = () => {
        const msg = `Hey! I am ${Name}. You can contact me on ${Contact}. My Query is ${Message}`
        const link = `https://api.whatsapp.com/send?phone=+918892225427&text=${msg}`;
        window.open(link, "blank");
    }

    return (
        <div id="contact" className={"contact"}>
            <div className={"homepage-contact-header"}>
                <h1>Contact Us</h1>
            </div>
            <div className="contact-inner">
                <div className="contact-left-container">
                    <Link target={"_blank"} to={"tel:+918892225427"}><svg className="icon-phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" /></svg></Link>
                    <Link target={"_blank"} to={`https://api.whatsapp.com/send?phone=+918892225427&text=Hi, I have a Query.`}><svg className="icon-whatsapp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" /></svg></Link>
                    <Link target={"_blank"} to={`https://instagram.com/lanternstudio19.in?igshid=ZDdkNTZiNTM`}><svg className="icon-instagram" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg></Link>
                    <Link target={"_blank"} to={`https://www.google.com/maps/place/Lantern+Studio,+No+25,+1st+Main+Rd,+Srigandha+kaval,+Bharath+Nagar,+Chandrashaker+layout,+Srigandadakaval,+Bengaluru,+Karnataka+560091/data=!4m2!3m1!1s0x3bae3decf3c28a55:0x6097ce7cb585fec4?utm_source=mstt_1&entry=gps&g_ep=CAESCTExLjcxLjMwMhgAIIgnKgBCAklO`}><svg className="icon-maps" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 16l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 4-9 3.96-1.584c.38.516.741 1.08 1.061 1.729l-3.523 1.41-1.725 3.88 2.672-1.01 1.506-2.687-.635 3.044 4.189 1.789.495-2.021.465 2.024 4.15-1.89-.618-3.033 1.572 2.896 2.732.989-1.739-3.978-3.581-1.415c.319-.65.681-1.215 1.062-1.731l4.021 1.588 3.936 9z" /></svg></Link>
                    <Link target={"_blank"} to={`https://www.youtube.com/@lanternstudio1950`}><svg className="icon-email" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" /></svg></Link>
                </div>
                <div className="contact-right-container">
                    <div className="contact-form">
                        <p className="label">Name</p>
                        <input onChange={(e) => { setName(e.target.value) }} type="text" />
                        <p className="label">Contact No.</p>
                        <input onChange={(e) => { setContact(e.target.value) }} type="text" />
                        <div className="contact-budget">
                            <div>
                                <p className="label">Occasion</p>
                                <input onChange={(e) => { setMessage(e.target.value) }} type="text" />
                            </div>
                            <div className="spacer"></div>
                            <div>
                                <p className="label">Budget</p>
                                <input onChange={(e) => { setMessage(e.target.value) }} type="text" />
                            </div>
                        </div>
                        <p className="label">Message</p>
                        <input onChange={(e) => { setMessage(e.target.value) }} type="text" />
                    </div>
                    <button onClick={whatsapp} className="submit-button">Submit</button>
                </div>
            </div>
        </div>
    );
}