import "./details.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { BiArrowBack, BiFolder, BiPhoneCall } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import { FacebookEmbed, InstagramEmbed } from "react-social-media-embed";
import { Link } from "react-router-dom";
import { collection, getDocs, where, query } from 'firebase/firestore';
import { db } from '../../firebase';

export const DetailPage = (props) => {

    const [isMobile, setMobile] = useState(false);
    const [isNull, setNull] = useState(false);
    const [couples, setCouples] = useState([]);
    const [explore, setExplore] = useState(true);
    const [exploreVideos, setExploreVideso] = useState([]);
    const [couplesSection, setCouplesSection] = useState(true);
    const [Socials, setSocials] = useState(true);
    const type = props.type.toString();

    const whatsapp = () => {
        const msg = "Hey! Are you available for a gig"
        const link = `https://api.whatsapp.com/send?phone=+919742002737&text=${msg}`;
        window.open(link, "blank");
    }

    const getVideos = async () => {
        const q = query(collection(db, 'videos'), where("genre", "==", type));
        await getDocs(q).then((query) => {
            const data = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setExploreVideso(data);
            console.log(data);
        })
    }

    const getcouples = async () => {
        const q = query(collection(db, 'couples'), where("genre", "==", type));
        await getDocs(q).then((query) => {
            const data = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setCouples(data);
            console.log(data);
        })
    }

    function extractVideoId(url) {
        let regExp = /youtu\.be\/([^#\&\?]{11})|youtube\.com\/.*v=([^#\&\?]{11})/;
        let match = url.match(regExp);
        if (match && match.length >= 2) {
            return match[1] || match[2];
        }
        return null;
    }

    useEffect(() => {
        window.scrollTo({ top: 0 });
        if (window.screen.width <= 900) {
            setMobile(true);
        } else {
            setMobile(false)
        }
        // if (props.type === "wedding") {
        //     setCouplesSection(true);
        // } else {
        //     setCouplesSection(false);
        // }
        if (explore.length === 0) {
            setNull(true);
        } else {
            setNull(false);
        }
        getcouples();
        getVideos();
        window.addEventListener("resize", () => {
            if (window.screen.width <= 900) {
                setMobile(true);
            } else {
                setMobile(false)
            }
        });
    }, []);


    return (
        <div className={"details"}>
            <div className={"details-header"}>
                <Link to={"/"}>
                    <BiArrowBack color={"black"} className={"leading-icon"}></BiArrowBack>
                </Link>
            </div>
            <div className={"details-intro"}>
                <img className={"background-image"} src={props.bgImage} />
                <div className={"details-mask"}>
                    <div className={"quote"}>
                        <h1>
                            {props.quote}
                        </h1>
                        <p>{props.author}</p>
                    </div>
                    <div className={"intro-bottom"}>
                        <h1>
                            {props.title}
                        </h1>
                        <div onClick={whatsapp} className={"intro-bottom-button"}>
                            <div className="img"></div>
                            Whatsapp Us
                        </div>
                    </div>
                </div>
            </div>
            {explore && <div className={props.imageList.length === 0 ? "container-hide" : "details-explore"}>
                <h1>Explore</h1>
                <div className="explore-images">
                    {
                        props.imageList.slice(0, 11).map((imageURL, props) =>
                            <div className="explore-frame">
                                <img src={imageURL} alt={"explore image"} />
                            </div>)
                    }
                </div>
                <div className="bottom-more">
                    <Link state={type} to={"/explore"} className={"details-explore-button"}>
                        <div className="border"></div>
                        Explore More
                    </Link>
                </div>
                {/* <div className={"explore-swiper"}>
                    <Swiper
                        slidesPerView={isMobile ? 1 : 2}
                        spaceBetween={50}
                        slidesPerGroup={isMobile ? 1 : 2}
                        navigation
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{ delay: 2000 }}
                        modules={[Navigation, Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {
                            props.imageList.map((imageURL, props) =>
                                <SwiperSlide className="explore-frame">
                                    <img src={imageURL} alt={"explore image"} />
                                </SwiperSlide>)
                        }
                    </Swiper>
                    <div className={"explore-button-container"}>
                        <Link state={type} to={"/explore"} className={"details-explore-button"}>
                            <div className="border"></div>
                            Explore More
                        </Link>
                    </div>
                    <div className={"drag-tip"}>
                        <svg width="305" height="6" viewBox="0 0 305 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M305 3L-2.86102e-06 3" stroke="url(#paint0_linear_78_123)" strokeWidth="5" />
                            <defs>
                                <linearGradient id="paint0_linear_78_123" x1="305" y1="5.50119" x2="58.5021"
                                    y2="5.50119" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#323232" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>{isMobile ? "Drag" : "Drag or Scroll"}</p>
                        <svg width="305" height="6" viewBox="0 0 305 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 3L305 3" stroke="url(#paint0_linear_78_122)" strokeWidth="5" />
                            <defs>
                                <linearGradient id="paint0_linear_78_122" x1="-1.4366e-07" y1="5.50119" x2="246.498"
                                    y2="5.50119" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#323232" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div> */}
                <div className={"spacer"}></div>
            </div>}
            {exploreVideos && <div className={exploreVideos.length === 0 ? "container-hide" : "details-explore"}>
                <h1>Explore Videos</h1>
                <div className={"explore-swiper"}>
                    <Swiper
                        slidesPerView={isMobile ? 1 : 2}
                        spaceBetween={50}
                        slidesPerGroup={isMobile ? 1 : 2}
                        navigation
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{ delay: 2000 }}
                        modules={[Navigation, Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {
                            exploreVideos.map((video_link) => {
                                let url = extractVideoId(video_link['video_link']);
                                return <SwiperSlide className={"explore-tile"}>
                                    <iframe frameBorder={"0"} className="intro-link" src={"https://www.youtube.com/embed/" + url}></iframe>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                    <div className={"drag-tip"}>
                        <svg width="305" height="6" viewBox="0 0 305 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M305 3L-2.86102e-06 3" stroke="url(#paint0_linear_78_123)" strokeWidth="5" />
                            <defs>
                                <linearGradient id="paint0_linear_78_123" x1="305" y1="5.50119" x2="58.5021"
                                    y2="5.50119" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#323232" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>{isMobile ? "Drag" : "Drag or Scroll"}</p>
                        <svg width="305" height="6" viewBox="0 0 305 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 3L305 3" stroke="url(#paint0_linear_78_122)" strokeWidth="5" />
                            <defs>
                                <linearGradient id="paint0_linear_78_122" x1="-1.4366e-07" y1="5.50119" x2="246.498"
                                    y2="5.50119" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#323232" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>

                </div>
                <div className={"spacer"}></div>
            </div>}
            {<div className={couples.length === 0 ? "container-hide" : "details-explore"}>
                <h1>{type === "wedding" ? "Meet The Couples" : "Meet The Clients"}</h1>
                <div className={"explore-swiper"}>
                    <>
                        <Swiper
                            slidesPerView={isMobile ? 1 : 2}
                            spaceBetween={50}
                            navigation
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{ delay: 2000 }}
                            modules={[Navigation, Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            {
                                couples.map((value, props) => {
                                    return <SwiperSlide key={props} className={"couple-tile"}>
                                        <Link className="link" to={"/couple"} state={value}>
                                            <div className="tile-image">
                                                <img src={value['image']} alt={"coupleImage"}></img>
                                            </div>
                                            <div className={"tile-mask"}>
                                                <div className={"couple"}>
                                                    <h1>{value['coupleName']}</h1>
                                                </div>
                                            </div>
                                        </Link>
                                    </SwiperSlide>;
                                })
                            }
                        </Swiper>
                    </>
                    <div className={"drag-tip"}>
                        <svg width="305" height="6" viewBox="0 0 305 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M305 3L-2.86102e-06 3" stroke="url(#paint0_linear_78_123)" strokeWidth="5" />
                            <defs>
                                <linearGradient id="paint0_linear_78_123" x1="305" y1="5.50119" x2="58.5021"
                                    y2="5.50119" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#323232" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>{isMobile ? "Drag" : "Drag or Scroll"}</p>
                        <svg width="305" height="6" viewBox="0 0 305 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 3L305 3" stroke="url(#paint0_linear_78_122)" strokeWidth="5" />
                            <defs>
                                <linearGradient id="paint0_linear_78_122" x1="-1.4366e-07" y1="5.50119" x2="246.498"
                                    y2="5.50119" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#323232" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className={"spacer"}></div>
            </div>}
            {Socials && <div className={props.socials ? "details-explore" : "details-hidden"}>
                <h1>Socials</h1>
                <div className={"explore-swiper"}>
                    {props.socials && <Swiper
                        slidesPerView={isMobile ? 1 : 3}
                        spaceBetween={50}
                        grabCursor={true}
                        slidesPerGroup={isMobile ? 1 : 3}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{ delay: 2000 }}
                        modules={[Navigation, Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        <SwiperSlide className={"explore-tile"}>
                            <iframe frameBorder={"0"} src={props.socials.youtube}></iframe>
                        </SwiperSlide>
                        <SwiperSlide className={"explore-tile"}>
                            <InstagramEmbed url={props.socials.instagram} width="100%" height="100%" />
                        </SwiperSlide>
                        <SwiperSlide className={"explore-tile"}>
                            <FacebookEmbed url={props.socials.facebook} width="100%" />
                        </SwiperSlide>
                    </Swiper>}
                </div>
                <div className={"spacer"}></div>
            </div>}
            <div className={"details-footer"}>
                <h1>Resources</h1>
                <div className={"footer-links"}>
                    <Link to={"https://drive.google.com/drive/folders/1zeyOo3yIsohTtt50y-nGkVEaKWa-Q7Nx"} className={type === "wedding" ? "" : "container-hide"}>
                        <div className={"link-detail"}>
                            <BiFolder color={"black"} className={"link-icon"}></BiFolder>
                            <p>
                                Check Out The Full Collection of our wedding.
                            </p>
                        </div>
                    </Link>
                    <Link to={"/contactus"}>
                        <div className={"link-detail"}>
                            <BiPhoneCall color={"black"} className={"link-icon"}></BiPhoneCall>
                            <p>
                                Contact us now.
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className={"spacer"}></div>
        </div>
    )
}
