import React from 'react'
import "../Explore/Explore.scss"
import { ExploreCard } from '../Explore/ExploreCard'
import { useLocation } from 'react-router-dom';


export const Couple = () => {

    const location = useLocation();
    const data = location.state;
    const gallary = data["gallary"];

  return (
    <div className="explore">
      <div className={"header"}>
          <h1>{data.coupleName}</h1>
      </div>
      <div className="photos-container">
        {gallary && gallary.map((image, props)=>{
          return <ExploreCard image={image} key={props} />
        })}
      </div>
    </div>
  )
}
